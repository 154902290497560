// extracted by mini-css-extract-plugin
export var columnOne = "layout-module--column-one--DHtqa";
export var columnTwo = "layout-module--column-two--og9k6";
export var container = "layout-module--container--eLBMS";
export var emphasizedText = "layout-module--emphasized-text--tnny7";
export var footerCopyrightText = "layout-module--footer-copyright-text--96QMx";
export var footerLinkText = "layout-module--footer-link-text--EJiRk";
export var footerLinks = "layout-module--footer-links--+pjPY";
export var formEmail = "layout-module--form-email--fvjhC";
export var formSubmitButton = "layout-module--form-submit-button--1SYL0";
export var hero = "layout-module--hero--OVJey";
export var heroWrapper = "layout-module--hero-wrapper--THHUr";
export var infoMessage = "layout-module--info-message--9r2kL";
export var mcForm = "layout-module--mc-form--i6C8D";